import cuid from 'cuid'
import { performTrack } from 'helpers/tracking'
import useFacebookCookies from 'hooks/useFacebookCookies'

interface FacebookPixelInit {
  userId?: number
  userEmail?: string
}

interface FacebookPixelTrackEvent {
  eventName: string
  data?: object
  eventID: string
}

interface FacebookTrackEvent {
  userId?: number
  eventName: string
  data?: object
}

export default function useFacebook() {
  const { fbp, fbc } = useFacebookCookies()

  const _facebookPixelScript = () => {
    return (function (
      f: any,
      b: any,
      e: any,
      v: any,
      n?: any,
      t?: any,
      s?: any,
    ) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
    )
  }

  const _facebookInit = ({ userId }: FacebookPixelInit) => {
    const advancedMatchingParams = {
      ...(userId && {
        external_id: userId,
      }),
    }

    // I know, it sounds weird `allowDuplicatePageViews`, but it's the only way
    // to do it here is the logic behind it:
    //   - As this is a SPA we want to track the page view on a route change, and
    //   we do it disabling the push state when the history API is used
    //   - Then the pixel stops tracking the page view when the push state is triggered
    //   - We need the pixel to stop doing it otherwise it will track the page
    //   view WITHOUT the event ID deduplication
    //   - Then because we disabled the push state, the page view stopped working
    //   at all, even forcing it through the `fbq('track', 'PageView')`
    //   - The only way to do it is to allow the duplicate page view, although it
    //   is sending only a SINGLE event that is 100% controlled by us
    fbq.disablePushState = true
    fbq.allowDuplicatePageViews = true

    return fbq(
      'init',
      process.env.REACT_APP_FACEBOOK_PIXEL_ID!,
      advancedMatchingParams,
    )
  }

  const facebookTrack = ({ userId, eventName, data }: FacebookTrackEvent) => {
    if (typeof window !== 'undefined' && Boolean(userId)) {
      const eventID = cuid()
      const eventSourceUrl = window.location.origin + window.location.pathname

      performTrack({
        platform: 'facebook',
        eventName: eventName,
        payload: {
          event_id: eventID,
          event_source_url: eventSourceUrl,
          fbp: fbp,
          fbc: fbc,
          user_id: userId,
          ...data,
        },
      })

      facebookPixelTrack({ eventName, data, eventID })
    }
  }

  const facebookInit = ({ userId, userEmail }: FacebookPixelInit) => {
    _facebookPixelScript()
    _facebookInit({ userId, userEmail })
  }

  const facebookPixelTrack = ({
    eventName,
    data,
    eventID,
  }: FacebookPixelTrackEvent) => {
    fbq('track', eventName, { ...data }, { eventID })
  }

  return {
    facebookInit,
    facebookTrack,
    facebookPixelTrack,
  }
}
