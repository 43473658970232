import { useEffect } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import UserContext, { User } from 'context/UserContext'
import PlanContext from 'context/PlanContext'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation, useSearchParams } from 'react-router-dom'
import useApi from 'hooks/useApi'
import useSnowplow from 'hooks/useSnowplow'
import { RoutePaths } from 'route-paths'
import { FunnelRouter } from 'components/FunnelRouter'
import usePlanData from 'hooks/usePlanData'
import FacebookPixel from 'components/FacebookPixel'

export const LOCAL_STORAGE_USER_KEY = 'asanarebel.user'
export const LOCAL_STORAGE_DISCOUNT_COUPON = 'asanarebel.discount.coupon'

interface Props {
  locale: string
}

function App({ locale }: Props) {
  const [searchParams] = useSearchParams()
  const discountCodeParam = searchParams.get('discount_coupon')

  if (discountCodeParam) {
    localStorage.setItem(LOCAL_STORAGE_DISCOUNT_COUPON, discountCodeParam)
  }

  const { selectedPlan: plan, setSelectedPlan: setPlan } = usePlanData(locale)

  const location = useLocation()

  const [user, setUser] = useLocalStorageState<User>(LOCAL_STORAGE_USER_KEY)

  const { createGuest, sherlockTrack } = useApi(locale)

  const { snowplowInit, snowplowTrackPageView } = useSnowplow()

  const isNotMagicLogin = location.pathname !== RoutePaths.MagicLogin

  const initializeTrackers = () => {
    if (!user) {
      return
    }

    snowplowInit(`${user?.id}`, user?.experiments)
  }

  const createGuestUser = async () => {
    const guestUser = await createGuest([])

    setUser({
      ...guestUser.user,
      authToken: guestUser.token,
    })
  }

  useEffect(() => {
    if (!user?.id && isNotMagicLogin) {
      createGuestUser()
    } else if (!!user?.id && !!user?.authToken) {
      initializeTrackers()
    }
  }, [user?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const observer = new PerformanceObserver(
      (list: PerformanceObserverEntryList) => {
        for (const entry of list.getEntries()) {
          if (entry.name.includes('https://www.facebook.com/tr/')) {
            const urlParams = new URLSearchParams(entry.name.split('?')[1])
            const params: Record<string, string> = {}

            for (const [key, value] of urlParams.entries()) {
              params[key] = value
            }

            if (
              ['Purchase', 'InitiateCheckout', 'CompleteRegistration'].includes(
                params['ev'],
              )
            ) {
              sherlockTrack({ params })
            }
          }
        }
      },
    )

    observer.observe({ entryTypes: ['resource'] })

    return () => {
      observer.disconnect()
    }
  }, [sherlockTrack])

  useEffect(() => {
    if (!user) {
      return
    }

    snowplowTrackPageView()
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) {
    return null
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <PlanContext.Provider value={{ plan, setPlan }}>
        <FacebookPixel
          pixelId={process.env.REACT_APP_FACEBOOK_PIXEL_ID!}
          userId={user.id}
        />
        <FunnelRouter locale={locale} />
      </PlanContext.Provider>
    </UserContext.Provider>
  )
}

export default App
